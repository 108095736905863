<template>
  <div>
      <div class="title">{{$t('titleI18n.BankManage')}}</div>
      <div class="flex-row-reverse" style="margin-top: 10px;">
          <el-button type="primary" icon="el-icon-plus" @click="toDetail()">{{ $t('btnI18n.CreateBank') }}</el-button>
      </div>
      <el-table :data="tableArea" style="margin-top: 10px;">
          <template slot="empty">
              <div v-loading="tableLoading" v-text="$t('tableEmpty')"></div>
          </template>
          <el-table-column :label="$t('tableI18n.BankId')" prop="id" align="center"></el-table-column>
          <el-table-column v-if="language === 'zh-cn'" :label="$t('tableI18n.BankInfoName')" prop="cnName" align="center"></el-table-column>
          <el-table-column v-else :label="$t('tableI18n.BankInfoName')" prop="enName" align="center"></el-table-column>
          <el-table-column :label="$t('tableI18n.swiftCode')" prop="swiftCode" align="center"></el-table-column>
          <el-table-column :label="$t('tableI18n.Status')" prop="enable" align="center" width="160">
              <template v-slot="scope">
                  <el-switch
                      style="margin-left: 10px;"
                      class="switch-custom"
                      v-model="scope.row.enable"
                      @change="changeStatus(scope.row.id, scope.row.enable)"
                      :active-value="0" :inactive-value="1"
                      active-color="#DCDFE6" inactive-color="#81d8d0"
                      :active-text="$t('statusI18n.Enable')" :inactive-text="$t('statusI18n.Disable')">
                  </el-switch>
              </template>
          </el-table-column>
          <el-table-column :label="$t('tableI18n.Operation')" align="center">
              <template v-slot="scope">
                  <el-link class="list-opt" :underline="false" type="primary" @click="toDetail(scope.row.id)">{{ $t("btnI18n.Edit") }}</el-link>
              </template>
          </el-table-column>
      </el-table>
      <AdPagination :currentPage="tablePageCP" :pageSize="tablePagePS" :pageTotal="tableTotal" @handlePage="handlePage"></AdPagination>
  </div>
</template>

<script>
import AdPagination from "@/components/ad-pagination.vue";

export default {
    name: "bank-info",
    components: {AdPagination},
    data() {
        return {
            tableLoading: false,
            tableArea: [],
            tableTotal: 0,
            tablePageCP: 1,
            tablePagePS: 10,
            optionsStatus: this.$enums.ASSETS_STATUS,
            optionsValStatus: '',
        };
    },
    created() {
        this.initData();
    },
    methods: {
        handlePage(val) {
            this.tablePageCP = val.currentPage;
            this.tablePagePS = val.pageSize;
            this.initData();
        },
        changeStatus(id, enable) {
            let _this = this;
            this.$axios.post("/manage-bank/enable", {id: id, enable: enable.toString()}).then(res => {
                if (!res.code) {
                    _this.initData();
                }
            });
        },
        initData() {
            let _this = this;
            this.tableLoading = true;
            // FINANCING, APPLYING, PASS, REFUSE, EXPIRED
            let params = { page: this.tablePageCP, pageSize: this.tablePagePS };
            this.$axios.get('/manage-bank/list', { params: params }).then((res) => {
                _this.tableArea = res.data.rows || [];
                _this.tableTotal = res.data.count || 0;
                _this.tableLoading = false;
            }).catch(error => {
                console.log(error);
                _this.tableLoading = false;
            });
        },
        toDetail(id) {
            if (id) {
                this.$router.push({path: "/bank-info/info-ad", query: {id: id}});
            } else {
                this.$router.push({path: "/bank-info/info-ad"});
            }
        }
    },
    computed: {
        language() {
            return this.$store.getters['auth/language'];
        }
    },
    watch: {
        language() {
            console.log(this.language);
        },
    },
};
</script>

<style scoped>

</style>
